new WOW({ offset: 70 }).init();

$(document).ready(function () {

	if ($("#HomepageMarker").length === 0) {
		$(".top-wrapper__starburst").css("opacity", "0.2");
	}
	else {
		$(".category-links__link").addClass("wow fadeInUp").attr({ "data-wow-duration": "1.5s", "data-wow-offset": "120" });
		$(".top-wrapper__starburst").css({ "top": $(".starburst__marker").offset().top, "left": $(".starburst__marker").offset().left });
		if (window.matchMedia('(min-width:768px)').matches) {
			$(".header__logo").addClass("wow bounceIn").attr({ "data-wow-delay": "0.3s", "data-wow-duration": "1.5s" });
			$(".top-wrapper__starburst").addClass("wow fadeIn2");
			$(".top-wrapper__city-back").addClass("wow fadeInUp").attr({ "data-wow-delay": "0.3s", "data-wow-duration": "3s" });
			$(".top-wrapper__city-front").addClass("wow fadeInUp").attr({ "data-wow-delay": "0.6s", "data-wow-duration": "3s" });
		} else {
			$(".top-wrapper__starburst").css("opacity", "0.2");
		}
	};

	$("img.has-tooltip").tooltip({
		title: function () {
			return $(this).attr("alt");
		},
		placement: "auto"
	});

	$("a.has-tooltip").tooltip({
		placement: "auto"
	});

	function fixHeights() {
		$('.testimonials__container').sameHeight();
		$('.testimonials .item').sameHeight();
	}

	setTimeout(fixHeights, 300);

	$(window).on("resize", function () {
		setTimeout(fixHeights, 300);
	});

	$('.textContent h1').addClass('wow splitter');

	var splitter = document.getElementsByClassName('splitter');

	Splitting({
		target: splitter,
	});


});

